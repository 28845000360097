import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
// Table Imports
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import { Checkbox } from "@mui/material";
import ModalImage from "react-modal-image";
import moment from "moment";
import { getFileTypeFromUrl } from "../../../utils/helper";



const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const PetVaccine = ({ id }: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userType, setUserType] = useState("");
  const [history, setHistory] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);

  const getAllHistory = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/pet/getPetVaccine?page=${page}&limit=10&id=${id}`
      );
      console.log(data);

      setHistory(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  // const handleEnableDisable = async (event, id: any) => {
  const handleEnableDisable = async (event, vaccine: any) => {
    // console.log(event.target.checked, vaccine);

    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `dashboard/pet/updateVaccine?id=${vaccine?._id}`,
        // `dashboard/pet/updateVaccine?id=${id}`,
        {
          user: vaccine?.pet?.user,
          vaccineVerified: event.target.checked,
        }
      );
      toastMessage(
        `Vaccine updated successfully`,
        "success"
      );
      // setProducts((prev) =>
      //   prev.map((user) => {
      //     if (user._id == id) return data?.data;
      //     else return user;
      //   })
      // );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllHistory();
  }, [page]);

  return <>
    <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
    <div className="w-full flex flex-col gap-6 ">
      <div className="w-full">
        <Table
          sx={{
            borderCollapse: "separate !important",
            width: "100%",
          }}
        >
          <TableHead>
            <CustomTableHeadRow>
              <CustomTableHeadCell>Name of Vaccine</CustomTableHeadCell>
              <CustomTableHeadCell>Vaccination Date</CustomTableHeadCell>
              <CustomTableHeadCell>Valid Until</CustomTableHeadCell>
              <CustomTableHeadCell>
                Authorized Veterinarian</CustomTableHeadCell>
              <CustomTableHeadCell>
                <div className="flex justify-center">Vaccine Document</div>
              </CustomTableHeadCell>
              <CustomTableHeadCell>Verification</CustomTableHeadCell>
            </CustomTableHeadRow>
          </TableHead>
          <TableBody>
            {history.map((vaccine: any) => {

              const vaccineDocType = getFileTypeFromUrl(vaccine?.documentUrl);
              // console.log("vaccineDocType: ", vaccineDocType);

              return (
                <CustomTableBodyRow key={vaccine?._id} >

                  <CustomTableBodyCell>
                    {vaccine?.vaccine?.name ? vaccine?.vaccine?.name : vaccine?.vaccineName}
                  </CustomTableBodyCell>
                  <CustomTableBodyCell>
                    {moment(vaccine?.vaccinationDate).format("DD-MM-YYYY")}
                  </CustomTableBodyCell>
                  <CustomTableBodyCell>
                    {moment(vaccine?.vaccinationDate).add(vaccine?.validUntil, 'years').format("DD-MM-YYYY")}
                  </CustomTableBodyCell>
                  <CustomTableBodyCell>
                    {vaccine?.vet?.centerName ? vaccine?.vet?.centerName : vaccine?.vetName}
                  </CustomTableBodyCell>

                  <CustomTableBodyCell>
                    <div className="flex justify-center items-center">
                      {
                        vaccineDocType === "pdf" &&
                        <a
                          href={vaccine?.documentUrl}
                          download
                          className="block px-4 py-2 bg-blue-500 text-white text-center rounded"
                        >
                          Download PDF
                        </a>
                      }
                      {
                        vaccineDocType === "image" &&

                        <ModalImage
                          // small="/assets/images/pet.jpeg"
                          // medium="/assets/images/pet.jpeg"
                          // large="/assets/images/pet.jpeg"
                          small={vaccine?.documentUrl}
                          // medium={vaccine?.documentUrl}
                          large={vaccine?.documentUrl}
                          // hid the Download button because of CORS issue, add your live frontend url in aws s3 CORS configuration
                          hideDownload={true}
                          // hideZoom={false}
                          showRotate={true}
                          alt="Vaccine Document"
                          className="w-24 rounded-lg overflow-hidden"
                        />
                      }
                    </div>
                  </CustomTableBodyCell>

                  <CustomTableBodyCell>
                    {/* {vaccine?.vet?.centerName ? vaccine?.vet?.centerName : vaccine?.vetName} */}
                    <div className="flex justify-center">
                      <Checkbox
                        checked={vaccine?.verified}
                        onChange={(e) => handleEnableDisable(e, vaccine)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="secondary"
                      />
                    </div>
                  </CustomTableBodyCell>

                </CustomTableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="w-full flex justify-end items-center p-4">

        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          shape="rounded"
          color="secondary"
          onChange={(e, value) => setPage(value)}
        />
      </div>
    </div>

  </>;
};

export default PetVaccine;
