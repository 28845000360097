import { useEffect, useState } from 'react';
import {
  useParams,
  // useSearchParams, 
  // useLocation,
} from "react-router-dom";

import Loading from "react-fullscreen-loading";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
// Table Imports
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { callAxios } from '../../../utils/axios';
import { styled } from "@mui/material/styles";
import { toastMessage } from '../../../utils/toast';
import { useAppSelector } from "../../../store/hooks";
import dayjs from "dayjs";



const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const Pet = () => {
  const [loading, setLoading] = useState(false);
  const [pet, setPet] = useState<any>({});
  const { user } = useAppSelector((state) => state?.user);
  // const location = useLocation();
  // console.log("location: ", location);

  let params = useParams();
  // console.log("params: ", params);
  // console.log("params.id: ", params.id);
  // console.log("params.id: ", encodeURIComponent(params.id));

  // const [searchParams, setSearchParams] = useSearchParams();
  // console.log("searchParams: ", searchParams.getAll("a")); // get the query search param name "a"


  const getPet = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/pet/getPet/${encodeURIComponent(params.id)}`
      );
      console.log("data: ", data);

      setPet(data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, 'error');
    }
  };

  useEffect(() => {
    getPet();
  }, []);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />

      <div className="w-full flex flex-col items-center gap-6 publicSans p-6">
        <div className="w-full flex justify-center items-center">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Pet Detail
          </h6>
        </div>
        <div className="xs:w-96 sm:w-2/3 lg:w-1/2 2xl:w-1/3 flex flex-col items-center gap-9 bg-white rounded-lg shadow py-4 px-6">
          <div className="w-full flex justify-center items-center py-2">
            <div className='rounded-lg border- border-[#ED8014] overflow-'>
              <img
                src={pet?.profilePicture?.[0]?.url}
                // src="/assets/icons/search.svg"
                alt=""
                className="w-56 h-48 rounded-lg object-cover border-4 border-[#ED8014]"
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2 ">
              <div className='w-28 flex justify-between'>
                Name
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.name}
              </div>
            </div>
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
              <div className='w-28 flex justify-between'>
                Number
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.microchipNumber}
              </div>
            </div>
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
              <div className='w-28 flex justify-between'>
                Category
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.petCategory?.name}
              </div>
            </div>
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
              <div className='w-28 flex justify-between'>
                Breed
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.breed}
              </div>
            </div>
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
              <div className='w-28 flex justify-between'>
                Age
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.age} {pet?.age > 1 ? "Years" : "Year"} Old
              </div>
            </div>
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
              <div className='w-28 flex justify-between'>
                Weight
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.weight} Kg
              </div>
            </div>
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
              <div className='w-28 flex justify-between'>
                Gender
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.gender}
              </div>
            </div>
            <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
              <div className='w-28 flex justify-between'>
                Vaccinated
                <span>:</span>
              </div>
              <div className='font-semibold text-gray-800 break-all'>
                {pet?.anyPetVaccineVerified ? "Yes" : "No"}
              </div>
            </div>
            {
              pet?.petCategory?.name === "Dog" &&
              <div className="flex gap-2 font-medium text-xl text-gray-500 py-2">
                <div className='w-28 flex justify-between'>
                  Parvo Tested
                  <span>:</span>
                </div>
                <div className='font-semibold text-gray-800 break-all'>
                  {pet?.parvoTest?.verified ? "Yes" : "No"}
                </div>
              </div>
            }
          </div>

        </div>


      </div>
    </>
  );
};

export default Pet;
