// ######################### Helper Functions #########################



export const getFileTypeFromUrl = (url) => {
    const extension = url?.split('.')?.pop()?.split(/\#|\?/)[0].toLowerCase();

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    if (extension === "pdf") {
        return "pdf";
    } else if (imageExtensions.includes(extension)) {
        return "image";
    }
    return extension;
};

// export { getFileTypeFromUrl };